export const initDom = () => {
  const link = document.createElement('link');
  link.setAttribute('rel', 'preconnect');
  link.setAttribute('href', import.meta.env.REGIONDO_CDN_URL || '');
  document.head.append(link);

  const hasMetaDescription = !!document.querySelector('meta[name=description]');
  if (hasMetaDescription) return;
  const meta = document.createElement('meta');
  meta.setAttribute('name', 'description');
  meta.setAttribute('content', 'Guest widget product catalog');
  document.head.append(meta);
};
