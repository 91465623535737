import { classes as dayPicker } from '../components/Calendar/classes';

export const booking = {
  prefix: 'Gw-b',
  root: 'guest-booking-widget',
  widget: 'guest-widget',
  content: 'guest-content',
  introText: 'guest-intro-text',
  calendar: 'guest-calendar',
  voucher: 'guest-voucher',
  amount: 'guest-amount',
  input: 'guest-input',
  select: 'guest-select',
  loading: 'guest-loading',
  loadingMain: 'guest-loading-main',
  addToCart: 'guest-addtocart',
  iconRemove: 'guest-icon-remove',
  qty: {
    root: 'guest-qty',
    plus: 'guest-qty__plus',
    minus: 'guest-qty__minus',
  },
  buttons: {
    button: 'guest-button',
    addToCart: 'guest-button-addtocart',
    goToCart: 'guest-go-to-cart',
    checkout: 'guest-button-checkout',
    back: 'guest-button-back',
    coupon: 'guest-button-coupon',
    continue: 'guest-button-continue',
    secondary: 'guest-button-secondary',
    applyCoupon: 'guest-button-coupon-apply',
    upsell: 'guest-button-upsell',
    policyAccept: 'guest-button-policy-accept',
  },
  tabs: {
    tabs: 'guest-tabs',
    tab: 'guest-tabs__tab',
    tabActive: 'guest-tabs__tab--active',
    nav: 'guest-tabs__nav',
  },
  steps: 'guest-steps',
  step: {
    root: 'guest-step',
    variation: 'guest-step-variation',
    date: 'guest-step-date',
    time: 'guest-step-time',
    title: 'guest-step__title',
    header: 'guest-step__header',
    selected: 'guest-step--selected',
    edit: 'guest-step__edit',
    editExpanded: 'guest-step__edit--expanded',
    value: 'guest-step__value',
    radius: 'guest-radios',
  },
  times: {
    period: 'guest-times__period',
    group: 'guest-times__group',
    periods: {
      night: 'guest-times--night',
      morning: 'guest-times--morning',
      afternoon: 'guest-times--afternoon',
      evening: 'guest-times--evening',
    },
  },
  time: {
    time: 'guest-time',
    available: 'guest-time--available',
    highDemand: 'guest-time--high-demand',
    soldOut: 'guest-time--sold-out',
    selected: 'guest-time--selected',
  },
  checkout: {
    checkout: 'guest-checkout',
    cart: 'guest-checkout--cart',
    payment: 'guest-checkout--payment',
    contact: 'guest-checkout--contact',
    progress: 'guest-checkout-progress',
    totals: 'guest-checkout-totals',
  },
  cart: {
    table: 'guest-table',
    tableHeader: 'guest-table__header',
    tableCell: 'guest-table__cell',
    tableRow: 'guest-table__row',
    cart: 'guest-cart',
    empty: 'guest-empty-cart',
    emptyText: 'guest-empty-cart-text',
    productDesc: 'guest-product-option-name',
    removedOption: 'guest-product-option__removed',
    productQtyQndPrice: 'guest-product-qty-price',
    note: 'guest-note',
  },
  product: {
    name: 'guest-product-name',
    datetime: 'guest-product-datetime',
    date: 'guest-product-date',
    time: 'guest-product-time',
    gift: 'guest-product-gift',
    valueVoucher: 'guest-product-valuevoucher',
    option: {
      name: 'guest-option__name',
      title: 'guest-option__title',
    },
    price: 'guest-product-price',
    priceLabel: 'guest-product-price__label',
    quantity: 'guest-product-qty',
    description: 'guest-product-description',
    image: 'guest-product-images',
  },
  progress: {
    cart: 'guest-progress__cart',
    contact: 'guest-progress__contact',
    payment: 'guest-progress__payment',
    label: 'guest-progress-label',
    icon: 'guest-progress-icon',
    iconDisabled: 'guest-progress-icon--disabled',
    iconActive: 'guest-progress-icon--active',
    iconCompleted: 'guest-progress-icon--completed',
  },
  totals: {
    totals: 'guest-totals',
    title: 'guest-totals__title',
    grandTotal: 'guest-totals--grand-total',
    subTotal: 'guest-totals--subtotal',
    value: 'guest-totals__value',
    tax: 'guest-totals--tax', // @TODO Needs totals implementation
    vat: 'guest-totals-vat', // @TODO Needs totals implementation
    deposit: 'guest-totals--deposit',
  },
  field: {
    title: 'guest-fields-title',
    field: 'guest-field',
    from: 'guest-field--from',
    to: 'guest-field--to',
    message: 'guest-field--message',
  },
  form: {
    root: 'guest-form',
    intro: 'guest-form-intro',
    hint: 'guest-form-hint',
  },
  reservation: {
    root: 'guest-reservation',
    time: 'guest-reservation__time',
    message: 'guest-reservation__message',
  },
  /** dayPicker classes kept for backwards compatibility */
  dayPicker,
  modal: {
    root: 'guest-modal',
    inner: 'guest-modal-inner',
    header: 'guest-modal-header',
    body: 'guest-modal-body',
    close: 'guest-modal-close',
  },
  price: {
    root: 'guest-prices',
    discount: 'guest-price guest-price__discount',
    regular: 'guest-price guest-price__regular',
    regularWithOriginal: 'guest-price guest-price__regular guest-price__with_original',
    original: 'guest-price guest-price__original',
  },
  paymentMethods: {
    root: 'guest-method',
    type: (type: string) => `guest-method--${type}`,
    active: 'guest-method-active',
    content: 'guest-method__content',
    title: 'guest-method__title',
  },
  link: 'guest-link',
  soldOut: {
    root: 'guest-sold-out',
    inner: 'guest-sold-out__inner',
    title: 'guest-sold-out__title',
    text: 'guest-sold-out__text',
    phoneContainer: 'guest-sold-out__phone',
    phone: 'guest-phone',
    label: 'guest-phone-label',
  },
  upsell: {
    root: 'guest-upsell',
    drawerImage: 'guest-upsell__drawer-image',
  },
  navigation: {
    root: 'guest-navigation',
  },
};
